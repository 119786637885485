@import '../../components/sass-assets/vars.scss';

.homepage__:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 755px;
  z-index: -10;
  background: #C4C4C4;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.homepage__ {
  display: grid;
  grid-template-columns: minmax(60px, 1fr) repeat(6, minmax(1fr, 190px)) minmax(
      60px,
      1fr
    );
  grid-template-rows: 120px 1fr;
}

main {
  grid-column: 1 / span 8;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  
}

@media only screen and (max-width: 1100px) {
  .homepage__{
    background-position: 48% 33%;
    background-size:  cover;
  }
}

@media only screen and (max-width: $small) {

  main {
    grid-column: 1 / span 12;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
}
