@import '../sass-assets/vars.scss';

.items {
    display: flex;
      flex-direction: column;
      gap: 25px;

      .for-mob {
        display: none;
        h3 {
          font-size: $fs-bg;
          margin-bottom: 8px;
        }
      }
}

.loading {
  font-size: $fs-bg;
  font-weight: $fw-bg;
  text-align: center;
  margin-bottom: 20px;
}
  
@media only screen and (max-width: $medium) {
  .for-mob {
    display: unset !important;
  }
}  