@import '../sass-assets/vars.scss';

input[type="submit"] {
  margin-top: 10px; 
  padding: 10px 20px;
}

input[type="submit"]:hover {
  background: #fff;
  color: $dark1;
}

.button-container {
  display: flex;
  justify-content: center;
}

.login-form {
  padding: 2rem;
  width: 340px;
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: $fs-sm;
}

.title {
  font-size: $fs-bg;
  margin-bottom: 20px;

  &-sub {
    margin-bottom: 15px;
  }
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px;
}

.admin-link {
  color: $dark60;
  font-size: $fs-md1;
  font-weight: $fw-md;
  text-decoration: underline;

  &:hover {
    color: $dark;
  }
}