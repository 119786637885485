@import "../sass-assets/vars.scss";

.header {
  grid-column: 1 / span 8;
  display: grid;
  grid-template-columns: repeat(8, 1fr);

  & > nav {
    grid-column: 2 / span 6;
    display: flex;
    align-items: center;
    & > a {
      margin-top: 9px;
    }
  }

  .links {
    margin-left: auto;
    & a {
      font-size: $fs-md;
      transition: $transition;
      font-weight: $fw-md;
      padding: 10px;
      margin-right: 40px;
    }
  }

  .links :hover {
    background-color: $light;
    border-radius: 3px;
    box-shadow:  rgb(49 65 87) 0px 10px 20px -10px;
  }

  &__menu {
    display: none;
  }
}

@media only screen and (max-width: $xsmall) {
  .links :first-child {
    display: none;
  }
  .links :nth-child(2) {
    display: none;
  }
  .links :nth-child(3) {
    display: none;
  }
}

@media only screen and (max-width: $xmedium) {
  .links :nth-child(3) {
    display: none;
  }
}

@media only screen and (max-width: $medium) {
  .logo {
    width: 85%;
  }
}

@media only screen and (max-width: $small) {
  nav .links {
    display: none;
  }

  .header {
    grid-column: 1 / span 12;
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    & > nav {
      grid-column: 2 / span 10;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__menu {
      display: unset;
      
      span {
        display: block;
        width: 26px;
        height: 2px;
        background-color: black;

        &:not(:last-child) {
          margin-bottom: 3px;
        }
      }
    }
  }
}
