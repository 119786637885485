@import "../../components/sass-assets/vars.scss";

.filter-bar {
  width: 28%;
  padding: 10px;
  & h3 {
    font-size: $fs-bg;
    font-weight: $fw-bg;
    padding: 4px 0;
  }

  & h5 {
    font-size: $fs-sm;
    font-weight: $fw-md;
    padding-bottom: 5px;
  }

  .sorting {
    &__buttons {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 5px;
      gap: 5px;
      &__button {
        font-size: $fs-sm;
        
      }
    }
  }

  .filtering {
    margin-top: 5px;
    &__button {
      margin: 4px 0;
      font-size: $fs-sm;
      &:focus {
        background: white;
        color: $button-dark;
      }
    }
  }

  .active {
    background-color: white;
    color: $dark;
  }
}


@media only screen and (max-width: $medium) {
  .filter-bar {
    display: none;
  }
}
