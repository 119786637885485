@import '../sass-assets/vars.scss';

.pin { 
    display: flex;
    align-items: center;
  }

  .pin-icon {
    font-size: 2rem;
    color: $dark;
    min-width: 20px;
    
    &>path {
        color: $dark;
    }
  }
  
  .pin-text {
    font-size: 1.7em;
    color: $dark;
    font-weight: 700;
  }