@import "../sass-assets/vars.scss";

.footer {
  grid-column: 1 / span 8;
  display: grid;
  background-color: transparent;
  grid-template-columns: repeat(8, 1fr);
  margin-top: 20px;

  .columns {
    padding: 20px;
    grid-column: 2 / span 6;
    background-color: $light;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 3px;

    h3 {
      font-size: $fs-md1;
      font-weight: $fw-bg;
      padding: 4px 0;
    }

    li,
    p {
      font-size: $fs-md;
    }
    .links-contact-list {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }
  }

  .portfolio-link {
    padding: 7px;
    transition: all 0.3s ease-in-out 0s;
    border-radius: 3px;
    a {
      text-decoration: underline;
    }
    &:hover {
      cursor: pointer;
      box-shadow: rgb(49 65 87) 0px 10px 20px -10px;
    }
  }
}

@media only screen and (max-width: $small) {
  .footer {
    grid-column: 1 / span 12;
    display: unset;
    .links-contact-list {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
}
