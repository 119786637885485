@import "../sass-assets/vars.scss";

form.contact-form {
  max-width: 420px;
  margin: 50px auto;
}

.feedback-input {
  color: $dark;
  font-family: "Smooch Sans", sans-serif;
  font-size: $fs-md;
  font-weight: $fw-md;
  border-radius: 5px;
  line-height: 22px;
  background-color: transparent;
  border: 2px solid $dark;
  transition: all 0.3s;
  padding: 13px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  outline: 0;
}

.feedback-input:focus {
  border: 2px solid #cc4949;
}

textarea {
  height: 150px;
  line-height: 150%;
  resize: vertical;
}

[type="submit"] {
  font-family: "Smooch Sans", sans-serif;
  width: 100%;
  background: $button-dark;
  border-radius: 5px;
  border: solid 2px;
  cursor: pointer;
  color: white;
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0.3s;
  margin-top: -4px;
  font-weight: 700;
  text-align: center;
}
[type="submit"]:hover {
  background: white;
  color: $button-dark;
}
