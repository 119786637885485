@import "../../components/sass-assets/vars.scss";

.about {
    min-height: 100vh;
    max-height: 100%;
    display: grid;
    grid-template-columns: minmax(60px, 1fr) repeat(6, minmax(1fr, 190px)) minmax(
        60px,
        1fr
      );
    grid-template-rows: 120px 1fr;

    &__info{
    grid-column: 2 / span 6;
    background-color: $light;
    padding: 30px 30px 50px 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: start;
    border-radius: 3px;
    justify-content: space-between;
    font-weight: $fw-bg;
    font-size: $fs-md1;
    }
}

@media only screen and (max-width: $small) {
  .about__info {
    grid-column: 2 / span 10;
  }
}