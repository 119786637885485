// * Variables 
// colors 
$accent: #304659;
$accentHover: #304659;
$dark: #304659;
$dark1: #314157;
$dark80: rgba(48, 70, 89, 0.8);
$dark60: rgba(48, 70, 89, 0.6);
$light: #f5f9fc;
$light2: #f5f9fcb7;
$button-dark: #2f4354;
// transition
$transition: all 0.3s ease-in-out 0s;
// font sizes
$fs-xsm: 0.9125rem;
$fs-sm: 1.3rem;
$fs-md: 1.4625rem;
$fs-md1: 1.525rem;
$fs-bg: 1.8625rem;
$fs-ng: 2.3635rem;
$fs-ng1: 2.9635rem;
$fs-bg1: 4.2875rem;
$fs-xbg: 4.675rem;
// font weights and
$fw-md: 500;
$fw-bg: 700;
// screen sizes
$big : 1400px;
$xmedium : 1264px;
$medium : 1024px;
$xsmall: 900px;
$small : 640px;
