@import "../sass-assets/vars.scss";

.quick_search {
  &__input {
    form {
      position: relative;
    }
    input,
    button {
      height: 2rem;
      border: 1px solid $dark1;
      background-color: transparent;
      color: $dark1;
    }

    input {
      font-size: $fs-md;
      font-weight: $fw-md;
    }

    button {
      & > p {
        margin-top: -9px;
        > img {
          height: 28px;
          margin-left: 5px;
        }
      }
    }

    input[type="search"] {
      width: 100%;
      padding: 0 1.6rem;
      appearance: none; 
      transition: all 0.3s cubic-bezier(0, 0, 0.43, 1.49);
      transition-property: width, border-radius;
      z-index: 1;
      position: relative;
    }
    button {
      display: none; 
      position: absolute;
      top: 4px;
      right: 0;
      width: 3rem;
      background: #fff;
      border-radius: 0 3px 3px 0;
    }
    input:not(:placeholder-shown) {
      border-radius: 3px 0 0 3px;
      width: calc(100% - 3rem);
      + button {
        display: block;
      }
    }
    label {
      position: absolute;
      clip: rect(1px, 1px, 1px, 1px);
      padding: 0;
      border: 0;
      height: 1px;
      width: 1px;
      overflow: hidden;
    }
  }

  .button-disable {
    pointer-events: none;
  }
}

@media (max-width: $xsmall) {
  .quick_search {
    &__input {
      & > form {
        border: 4px solid $dark;
        border-radius: 2px;
      }
      input:not(:placeholder-shown) {
        color: $dark80;
        font-weight: $fw-bg;
      }
    }
  }
}