@import "../sass-assets/vars.scss";

.features {
  grid-column: 2 / span 6;
  background-color: #f5f9fc;
  padding: 30px 30px 10px 30px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-self: center;
  border-radius: 3px;

  .feature {
    display: flex;
    margin: 20px 15px;
    justify-content: space-around;

    & img {
      width: 300px;
      
      margin-right: 20px;
    }

    &__info {
      display: flex;
      flex-direction: column;

      &:nth-child(3) {
        align-self: end;
      }
    }

    &__top {
      font-size: $fs-bg;
      font-weight: $fw-bg;
      text-transform: uppercase;
      margin-bottom: 10px;
      letter-spacing: 1px;
    }

    &__cta {
      align-self: end;
      margin-top: 45px;
      font-size: $fs-md;
      font-weight: $fw-md;

      & > a {
        cursor: pointer;
        transition: $transition;
        padding: 10px;
       
        &:hover {
          box-shadow:  rgb(49 65 87) 0px 10px 20px -10px;
        }
      }
    }

    &__bottom {
      width: auto;
      font-size: $fs-sm;
      font-weight: $fw-md;
    }
  }

  .contact {
    display: grid;
    grid-template-columns: 60% 37%;
    gap:3%;
    margin: 20px 15px;
  }
}
@media only screen and (max-width: $medium) {
  .features {
    grid-column: 1 / span 8;
  }

  .contact {
    display: unset !important;
  }
}

@media only screen and (max-width: $small) {
  .features {
    grid-column: 1 / span 12;
  }

  .feature {
    align-items: center;
    flex-direction: column;
    & img {
      margin-right: 0 !important;
      margin-bottom: 15px;
    }
    &__cta {
      margin-top: 20px !important;
    }
  }
}
