@import "../sass-assets/vars.scss";

.hero {
  grid-column: 2 / span 6;
  padding: 20px 0 40px 0;

  &__font {
    color: $light;
    font-family: 'Smooch Sans', sans-serif; 
    font-weight: $fw-bg;
    font-size: $fs-xbg;
  }

  &__img {
    grid-column: 2/span 6;
    &>img{
      margin-left: auto;
      margin-right: auto;
      display: block;
      max-width:100%;
      height:auto;
      border-radius: 3px;
    }
  }

  .icons {
    display: flex;
    margin-top: 35px;
    justify-content: space-around;

    .icon {
      display: flex;
      align-items: center;

      &__info {
        margin: 0 80px 0 20px;
      }

      &__top {
        font-size: $fs-md;
        font-weight: $fw-md;
        margin-bottom: 5px;
      }

      &__bottom {
        font-size: $fs-bg;
        font-weight: $fw-bg;
      }
    }
  }
}

@media only screen and (max-width: $medium) {
  .icon {
    &__img {
      height: 60px;
      width: auto;
    }

    &__top {
      font-size: $fs-sm;
    }

    &__bottom {
      font-size: $fs-md1;
    }
  }

  .hero__font {
    font-size: $fs-bg1;
  }
}

@media only screen and (max-width: $xsmall) {
  .hero {
    padding: 20px 0;
    grid-column: 2 / span 10;

    &__img {
      grid-column: 2/span 6;
    }
  }
  .hero__font {
    font-size: 2.9rem;
  }

  .icons {
    flex-direction: column;
    flex-wrap: wrap;

    .icon {
      margin-bottom: 20px;
    }
  }
}
