@import "../sass-assets/vars.scss";

.item {
  list-style-type: none;
  display: flex;
  gap: 20px;
  justify-content: center;

  .img {
    &__pic {
      width: 340px;
      height: 255px;
      padding-top: 56.25%;
    }
  }

  .desc {
    display: flex;
    flex-direction: column;
    & > h3 {
      font-size: $fs-ng;
    }
    font-size: $fs-sm;

    &__contact {
      display: flex;
      gap: 0.5rem;
      justify-content: center;
      margin-top: 1rem;
      font-size: $fs-md;
      font-weight: $fw-md;
      & > a {
        cursor: pointer;
        transition: $transition;
        padding: 10px;

        &:hover {
          color: $dark;
          border-radius: 3px;
          box-shadow:  rgb(49 65 87) 0px 10px 20px -10px;
        }
      }
    }
  }

}

span {
  &.price {
    color: #cc7e18;
    font-weight: $fw-bg;
    font-size: $fs-bg;
    letter-spacing: 2.5px;

  }
  font-size: $fs-md1;
}

@media only screen and (max-width: $medium) {
  .item {
    flex-direction: column;
    margin-bottom: 12px;

    .img {
      &__pic {
        width: unset;
      }
    }
  }


}
