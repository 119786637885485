@import "../sass-assets/vars.scss";

$bar-width: 40px;
$bar-height: 4px;
$bar-spacing: 10px;

.menu-wrapper {
  width: $bar-width;
  height: $bar-height + $bar-spacing * 2;
  cursor: pointer;
  
}

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: $bar-width;
  height: $bar-height;
}

.hamburger-menu {
  position: relative;
  transform: translateY($bar-spacing);
  background: $dark1;
  transition: all 0ms 300ms;

  &.animate {
    background: rgba(255, 255, 255, 0);
  }
}

.hamburger-menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: $bar-spacing;
  background: $dark1;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu:after {
  content: "";
  position: absolute;
  left: 0;
  top: $bar-spacing;
  background: $dark1;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.overlay {
  position: absolute;
  top: 14%;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-image: linear-gradient(#003b73, transparent);
  @media only screen and (max-width: $small) {
    top: 17%;
  }
}

.header-list {
  background-color: #f5f9fc;;  
  border-radius: 5px;
  position: absolute;
  width: calc(100% - 55px);
  left: 50%;
  margin-top: 120px;
  transform: translateX(-50%);
  border: 2px solid #314157;
  
  a,
  a:hover {
    text-decoration: none;
    color: $dark1;
    padding: 0.825rem;
    display: block;
    text-align: center;
    font-weight: $fw-bg;
    font-size: $fs-md1;
  }
}

//Spacing (Yes it is the same as in _global.scs, but if i import main-styles.scss menu will break
.container {
  padding: 1.0625rem 1.5rem;
}

.has-fade {
  visibility: hidden;
}

.fade-in {
  animation: fade-in 350ms ease-in-out forwards;
  visibility: visible;
}

@keyframes fade-in {
  from {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}