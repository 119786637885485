@import "../../components/sass-assets/vars.scss";

.inventory {
  min-height: 100vh;
  max-height: 100%;
  display: grid;
  grid-template-columns: minmax(60px, 1fr) repeat(6, minmax(1fr, 190px)) minmax(
      60px,
      1fr
    );
  grid-template-rows: 120px 1fr;

  &__title {
    grid-column: 2 / span 6;
    font-size: $fs-bg;
    font-weight: $fw-bg;
    text-transform: uppercase;
    margin-bottom: 10px;
    letter-spacing: 1px;

    &__mob {
      display: none;
      grid-column: 2 / span 6;
      font-size: $fs-bg;
      font-weight: $fw-bg;
      text-transform: uppercase;
      margin-bottom: 10px;
      letter-spacing: 1px;
    }
  }

  &__grid {
    grid-column: 2 / span 6;
    background-color: $light;
    padding: 30px 30px 50px 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: start;
    border-radius: 3px;
    justify-content: space-between;

    .display {
      width: 68%;
    }
  }
}

.filter__menu {
  display: none;
  background-color: $light2;
  padding: 5px 12px;
  border-radius: 3px;

  &__item {
    margin: 10px 0;
    text-align: center;
  }
}

@media only screen and (max-width: $medium) {
  .display {
    width: 100% !important;
  }

  .inventory__title__mob {
    display: unset !important;
  }

  .inventory__grid {
    grid-column: 1 / span 12;
  }

  .filter__menu {
    display: flex !important;
    position: sticky;
  }
}
