@import url("https://fonts.googleapis.com/css2?family=Smooch+Sans&display=swap");

@import "./components/sass-assets/vars.scss";

:root {
  --accent: #304659;
  --accentHover: #304659;
  --dark: #304659;
  --dark80: rgba(48, 70, 89, 0.8);
  --dark60: rgba(48, 70, 89, 0.6);
  --dark95: rgba(48, 70, 89, 0.95);
  --light: #f5f9fc;
  --light2: #f5f9fcb7;
  --transition: all 0.3s ease 0s;
}

//* box sizings
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

//* fonts
* {
  font-family: "Smooch Sans", sans-serif;
  color: $dark1;
  letter-spacing: 1.3px;
  text-decoration: none;
}

.App:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 855px;
  z-index: -10;
  background: #C4C4C4;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.App {
  background: #C4C4C4;

}

ul {
  list-style-type: none;
}

input {
  font-size: $fs-md;
}

a,
.logo {
  cursor: pointer;
}

#overPost {
  color: white;
}

#overPost1 {
  background-color: white;
}

@media only screen and (max-width: $small) {
  .App {
    background-position: 56% 39%;
  }
}
